body {
  margin: 0;
  padding: 0;
  font-family: Avenir Next, Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  display: flex;
}

.spacer {
  flex: 1;
}

header {
  background: linear-gradient(267.19deg, #50A12A 2.35%, #10B921 49.14%);
  color: white;
  padding: 5px 20px;

  display: flex;
  align-items: center;
}
header h1 {
  margin: 0;
  display: inline;
  margin-right: 10px;
  font-size: 30px;
}
header span {
  font-size: 20px;
}

.sidebar {
  width: 250px;
  white-space: normal;
  word-break: break-all;
  padding-right: 20px;
  flex-shrink: 0;
}


.sidebar ul {
  padding-left: 20px;
  border-left: 1px solid #ddd;
}

.sidebar li {
  list-style-image: none;
  list-style-type: none;
  margin: 2px 0;
  border-radius: 5px;
}

li.drag-over {
  border: 1px solid #50A12A;
  margin: -1px;
}

.sidebar hr {
  border: 0;
  border-bottom: 1px solid #ddd;
}

.container {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 0;
}

.controls {
  padding: 20px;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0;
}

.search {
  width: 100%;
  min-height: 67px;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid #ddd;

  white-space: normal;
  word-break: break-all;
  cursor: text;
}

.search .clickable-token {
  cursor: pointer;

}


.results .clickable-token {
  cursor: pointer;
}

.search.error {
  border-color: red;
}

.search .operator {
  color: gray;
  font-style: italic;
  border: 1px solid transparent;
  padding: 5px 0;
  display: inline-block;
  margin: 5px;
}

.search .token {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px 10px;
  display: inline-block;
  margin: 5px;
}

.rule-preview {
  border: 0;
  max-width: 500px;
}


td .token {
  border: 1px solid transparent;
  display: inline-block;
  cursor: pointer;
  white-space: pre-wrap;
}

td .token:hover {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 5px 10px;
    
    margin: -5px -10px;
}

.search .ontology-token {
  padding: 5px 10px;
  border: 1px solid transparent;
  margin: 5px;
}

.search .token i {
  color: gray;
}

.search input {
  font: inherit;
  border: 0;
  outline: 0;
  padding: 5px 10px ;
  /* background: red; */
}

.search .input {
  background: green;
  height: 0;
  display: inline-grid;
}

.search .dropdown {
  border: 1px solid #ddd;
  border-radius: 10px;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  background: white;
  position: relative;
  left: -10px;
  z-index: 99;
  box-shadow: 5px 3px 12px rgba(0,0,0,0.1);
  min-width: 300px;
  /* max-height: 400px; */
  /* overflow: auto; */
}

.search .dropdown .result {
  padding: 5px 20px;
}

.search .dropdown .selected {
  background: rgb(220, 220, 220)
}

.results {
  flex: 1;
  overflow: auto;
  border-left: 1px solid #ddd;
}

.results table {
  width: 100%;
  border-spacing: 0;
}

thead tr:nth-child(1) th{
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 20px 0;
  padding-left: 20px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}


.result td {
  border-bottom: 1px solid #ddd;
  padding: 20px 0;
  padding-right: 10px;
  padding-left: 20px;
  min-width: 100px;
}

.result.hover td {
  border-bottom: 1px solid rgb(67, 233, 161);
  background: rgb(232, 255, 241);
}

.ontology-mini {
  border-radius: 10px;
  width: 10px;
  height: 10px;
  margin: 2px;
  display: inline-block;
}

.ontology-token, .ontology-inactive {
  border-radius: 5px;
  padding: 1px 10px;
  text-decoration: none;
  display: inline-block;
}

a.active .ontology-inactive.special {
  background: #ddd;
}

.sidebar .ontology-token, .sidebar .ontology-inactive {
  margin-left: -10px;
}

.ontology-token {
  color: white;
}

.sidebar a {
  color: black;
}


#tabs {
  padding: 10px 0;
  padding-bottom: 0;
  margin-right: 10px;
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
  margin-bottom: -1px;
}
.tab {
  display: inline-block;
  padding: 5px 20px;
  color: #adadad;
  border: 1px solid #ddd;
  border-bottom: 0;
  cursor: pointer;
  text-decoration: none;
  margin-right: 4px;
  border-radius: 3px 3px 0 0;
  text-shadow: 0 1px 0 white;
  opacity: 0.7;
  user-select: none;

  /*transition: .2s;*/
}
.tab:hover {
  opacity: 1;
  color: #888;
}
.tab-after {
  margin-right: -5px;
  display: inline-block;
  position: relative;
  left: 6px;
  text-align: center;
  opacity: 0.5;
}
.tab-after:hover {
  opacity: 1;
}
.tab.selected {
  /* background: #f7f7f7; */
  background: white;
  color: #222;
  opacity: 1;
}



.results.matches, .results.matches th, .tab.matches.selected {
  background: #f4fff4
}

.results.rejects, .results.rejects th, .tab.rejects.selected {
  background: #fff4f4
}


.controls h2 {
  margin-top: 0;
}

h2 input {
  font: inherit;
  border: 0;
  width: 100%;
}


.file-selector-button {
  width: 80px;
}